import * as React from "react";
import { Package } from "@/types/aurei";
import { formatBytes, formatDate } from "@/util";
import { PackageDetailsBar } from "@/components/PackageDetailsBar";
import { Transition } from "@headlessui/react";

interface PackageListingProps {
  pkg: Package;
  show?: boolean;
}

interface PackageDetailProps {
  title: string;
}

const PackageDetail: React.FC<PackageDetailProps> = React.memo(
  function PackageDetail({ title, children }) {
    return (
      <li className="flex flex-col break-words md:items-center md:justify-between md:flex-row space-y-1">
        <strong>{title}</strong>
        {children}
      </li>
    );
  }
);

const PackageListing: React.FC<PackageListingProps> = ({
  pkg,
  show = true,
}) => {
  const formattedDate = formatDate({ date: new Date(pkg.builddate * 1000) });
  const formattedPackagedSize = formatBytes(pkg.csize);
  const pkgUrl = React.useMemo(
    () => new URL(pkg.filename, process.env.REPO_URL).href,
    [pkg.filename]
  );
  const aurUrl = React.useMemo(
    () => new URL(pkg.base, "https://aur.archlinux.org/packages/").href,
    [pkg.base]
  );
  const buildDateJSON = new Date(pkg.builddate * 1000).toJSON();

  return (
    <Transition
      as={React.Fragment}
      show={show}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="border border-gray-100 shadow-sm dark:border-primary pkg target:border-primary gap-4 flex flex-col p-4"
        id={pkg.name}
      >
        <div className="space-y-2">
          <h3 className="space-x-2 text-2xl lg:text-3xl">
            <a href={`#${pkg.name}`}>{pkg.name}</a>
            <small className="text-base text-gray-400 select-all dark:text-white version">
              {pkg.version}
            </small>
          </h3>
          <PackageDetailsBar pkg={pkg} pkgUrl={pkgUrl} />
          <p className="p-0">{pkg.desc}</p>
          <ul className="flex flex-row items-center space-x-1">
            <li>
              <small className="text-sm text-gray-500 dark:text-white">
                <time dateTime={buildDateJSON}>{formattedDate}</time>
              </small>
            </li>
            <li>
              <small
                className="text-sm text-gray-500 dark:text-white"
                title={`${pkg.csize} bytes`}
              >
                {formattedPackagedSize}
              </small>
            </li>
          </ul>
        </div>
        <ul className="space-y-3">
          <PackageDetail title="AUR">
            <a href={aurUrl} target="_blank" rel="noopener noreferrer">
              {pkg.base}
            </a>
          </PackageDetail>
          <PackageDetail title="SHA256">
            <code className="inline-block p-1 text-xs break-words select-all dark:bg-black-pearl-400 bg-slate-100 text-primary dark:text-primary">
              {pkg.sha256sum}
            </code>
          </PackageDetail>
          <PackageDetail title="License(s)">
            {pkg.license.join(",")}
          </PackageDetail>
          {pkg.provides.length > 0 && (
            <PackageDetail title="Provides">
              <small className="space-x-1">
                {pkg.provides.map((provide) => (
                  <code
                    key={provide}
                    className="inline-block p-1 text-sm lg:text-xs break-words select-all dark:bg-black-pearl-400 bg-slate-100 text-primary dark:text-primary"
                  >
                    {provide}
                  </code>
                ))}
              </small>
            </PackageDetail>
          )}
          {pkg.conflicts.length > 0 && (
            <PackageDetail title="Conflicts">
              <small className="space-x-1">
                {pkg.conflicts.map((conflict) => (
                  <code
                    key={conflict}
                    className="inline-block p-1 text-sm lg:text-xs break-words select-all dark:bg-black-pearl-400 bg-slate-100 text-primary dark:text-primary"
                  >
                    {conflict}
                  </code>
                ))}
              </small>
            </PackageDetail>
          )}
        </ul>
      </div>
    </Transition>
  );
};

const SkeletonPackageListing: React.FC = () => {
  return (
    <div className="border border-gray-100 shadow-sm dark:border-primary pkg target:border-primary gap-4 flex flex-col p-4 space-y-4">
      <div className="space-y-6">
        <h3 className="rounded-md w-1/4 h-4 bg-primary opacity-50 block animate-pulse"></h3>
        <p className="p-0 w-1/2 opacity-50 rounded-md bg-primary-300 block h-4 animate-pulse"></p>
      </div>
      <ul className="space-y-3">
        <li className="flex flex-col break-words md:items-center md:justify-between md:flex-row space-y-1">
          <strong className="bg-primary-500 opacity-50 h-4 w-20 rounded-md animate-pulse"></strong>
          <span className="bg-primary-500 opacity-50 h-4 w-1/6 rounded-md animate-pulse"></span>
        </li>
        <li className="flex flex-col break-words md:items-center md:justify-between md:flex-row space-y-1">
          <strong className="bg-primary-500 opacity-50 h-4 w-36 rounded-md animate-pulse"></strong>
          <span className="bg-primary-500 opacity-50 h-4 w-24 rounded-md animate-pulse"></span>
        </li>
        <li className="flex flex-col break-words md:items-center md:justify-between md:flex-row space-y-1">
          <strong className="bg-primary-500 opacity-75 h-4 w-32 rounded-md animate-pulse"></strong>
          <span className="bg-primary-500 bg-opacity-40 h-4 w-20 rounded-md animate-pulse"></span>
        </li>
      </ul>
    </div>
  );
};

export { PackageListing, SkeletonPackageListing };
