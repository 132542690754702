import { InferGetStaticPropsType } from "next";
import * as React from "react";
import { dehydrate, QueryClient } from "react-query";
import { fetchPackages, usePackages } from "@/util";
import { InstallCodeBlock, PacmanCodeBlock } from "@/components/CodeBlocks";
import { PackageSearch } from "@/components/PackageSearch";

type IndexPageProps = InferGetStaticPropsType<typeof getStaticProps>;

const App: React.FC<IndexPageProps> = () => {
  return (
    <main className="container flex-1 max-w-6x">
      <header className="max-w-screen-md mx-auto text-center">
        <h1 className="my-6 lg:text-7xl text-primary-500">
          Aurei
          <small className="block text-xl text-gray-600 dark:text-white">
            Community Arch Repo
          </small>
        </h1>
        <p>
          Aurei is a community Arch repo that uses a CI to build AUR packages.
        </p>
        <p>
          You can thank{" "}
          <a
            href="https://github.com/aktowns"
            rel="noopener noreferrer"
            target="_blank"
          >
            @aktowns
          </a>{" "}
          and{" "}
          <a
            href="https://github.com/rmcfadzean"
            rel="noopener noreferrer"
            target="_blank"
          >
            @rmcfadzean
          </a>
        </p>
      </header>

      <section className="my-12 space-y-4 text-center">
        <h2 className="my-6">Getting started</h2>
        <p>
          Add this code block to your pacman.conf before{" "}
          <code className="p-0.5 px-1 break-words select-all dark:bg-black-pearl-400 bg-slate-100 text-primary dark:text-primary">
            [Core]
          </code>
        </p>

        <PacmanCodeBlock />
        <p>
          And make sure you import the{" "}
          <a
            href={process.env.PUBKEY_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            GPG key
          </a>
        </p>
        <InstallCodeBlock />
      </section>

      <section className="space-y-2">
        <PackageSearch />
      </section>
    </main>
  );
};

export const getStaticProps = async () => {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery(["packages"], () => fetchPackages());

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export default App;
