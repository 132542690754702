import * as React from "react";
import { Package } from "@/types/aurei";
import PackageIcon from "@/assets/images/icons/package.svg";
import SignatureIcon from "@/assets/images/icons/signature.svg";
import LinkIcon from "@/assets/images/icons/link-ext.svg";

interface PackageDetailsBarProps {
  pkg: Package;
  pkgUrl: string;
}

const PackageDetailsBar: React.FC<PackageDetailsBarProps> = ({
  pkg,
  pkgUrl,
}) => {
  const signatureUrl = `${pkgUrl}.sig`;
  return (
    <ul className="flex flex-row items-center space-x-3 lg:space-x-2">
      <li>
        <a
          href={pkgUrl}
          target="_blank"
          rel="noopener noreferrer"
          title={`Download ${pkg.name} Package`}
        >
          <PackageIcon className="w-5 h-5 lg:w-4 lg:h-4 fill-current text-primary hover:text-primary-600" />
        </a>
      </li>
      <li>
        <a
          href={signatureUrl}
          target="_blank"
          rel="noopener noreferrer"
          title={`${pkg.name} Package Signature`}
        >
          <SignatureIcon className="w-5 h-5 lg:w-4 lg:h-4 fill-current text-primary hover:text-primary-600" />
        </a>
      </li>
      <li>
        <a
          href={pkg.url}
          target="_blank"
          rel="noopener noreferrer"
          title={`${pkg.name} website`}
        >
          <LinkIcon className="w-5 h-5 lg:w-4 lg:h-4 fill-current text-primary hover:text-primary-600" />
        </a>
      </li>
    </ul>
  );
};

export { PackageDetailsBar };
