import * as React from "react";
import { Package } from "@/types/aurei";
import { PackageListing } from "@/components/PackageListing";

interface PackageListProps {
  packages: Package[];
  filteredPackages: Package[];
}

const PackageList: React.FC<PackageListProps> = React.memo(
  function PackageList({ packages, filteredPackages }) {
    return (
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-1">
        {packages.map((pkg) => (
          <PackageListing
            key={pkg.name}
            pkg={pkg}
            show={filteredPackages.includes(pkg)}
          />
        ))}
      </div>
    );
  }
);

export { PackageList };
