import * as React from "react";
import CloseIcon from "@/assets/images/icons/close.svg";
import { useThrottle } from "@/util";

interface PackageSearchInputProps {
  setQuery: (query: string) => void;
}

const PackageSearchInput: React.FC<PackageSearchInputProps> = ({
  setQuery,
}) => {
  const [value, setValue] = React.useState("");
  const throttledValue = useThrottle(value, 500);

  const handleChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setValue(e.currentTarget.value);
    },
    []
  );

  const resetQuery = React.useCallback(() => {
    setQuery("");
  }, []);

  React.useEffect(() => {
    setQuery(throttledValue);
  }, [throttledValue, setQuery]);

  return (
    <div className="relative">
      <label htmlFor="search" className="sr-only">
        Search Packages
      </label>
      <input
        type="search"
        placeholder="Search packages..."
        id="search"
        name="search"
        autoComplete="off"
        autoCapitalize="off"
        className="w-full p-4 pr-10 font-light border border-gray-200 shadow-sm outline-none font-lg focus:shadow-lg dark:bg-black-pearl-400 dark:border-black-pearl-300 dark:text-white dark:placeholder-white"
        onChange={handleChange}
        value={value}
      />
      <button
        onClick={resetQuery}
        className={`absolute inset-y-0 right-0 flex items-center pr-3 ${
          value === "" ? "hidden" : ""
        }`}
        aria-hidden="true"
      >
        <CloseIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
      </button>
    </div>
  );
};

export { PackageSearchInput };
