import { Package } from "@/types/aurei";

export interface FilterPackages {
  (packages: Package[], query?: string): Package[];
}

export const filterPackages: FilterPackages = (packages, query) => {
  if (!query) {
    return packages;
  }
  return packages.filter(
    (pkg) => pkg?.name?.includes(query) || query.length === 0
  );
};
