const UNITS = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

export const formatBytes = (bytes: number) => {
  let l = 0;
  while (bytes >= 1024 && ++l) {
    bytes = bytes / 1024;
  }

  return bytes.toFixed(bytes < 10 && l > 0 ? 1 : 0) + " " + UNITS[l];
};
