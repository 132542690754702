import * as React from "react";
import fetch from "cross-fetch";
import { useQuery } from "react-query";

import { Package } from "@/types/aurei";

export const fetchPackages = async (): Promise<Package[]> => {
  const url = process.env.PKG_JSON_URL;
  const opts = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const resp = await fetch(url, opts);

  return resp.json();
};

export const usePackages = () => {
  return useQuery(["packages"], () => fetchPackages());
};
