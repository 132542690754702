import * as React from "react";

interface InstallCodeBlockProps {}

interface PacmanCodeBlockProps {}

const InstallCodeBlock: React.FC<InstallCodeBlockProps> = React.memo(
  function InstallCodeBlock() {
    return (
      <pre className="block overflow-auto whitespace-pre-line">
        <code className="text-left inline-block p-1 px-4 py-2 break-words select-all dark:bg-black-pearl-400 bg-slate-100 text-primary dark:text-primary">
          {`curl ${process.env.PUBKEY_URL} | sudo pacman-key -a - && \ sudo
        pacman-key --lsign ${process.env.PUBKEY_FINGERPRINT}`}
        </code>
      </pre>
    );
  }
);

const PacmanCodeBlock: React.FC<PacmanCodeBlockProps> = React.memo(
  function PacmanCodeBlock() {
    return (
      <pre className="block overflow-auto whitespace-pre-line">
        <code className="text-left inline-block p-1 px-4 py-2 break-words select-all dark:bg-black-pearl-400 bg-slate-100 text-primary dark:text-primary">
          {`[aurei]
        Server = ${process.env.REPO_URL}`}
        </code>
      </pre>
    );
  }
);

export { InstallCodeBlock, PacmanCodeBlock };
