import * as React from "react";
import { Package } from "@/types/aurei";
import { PackageList } from "./PackageList";
import { SkeletonPackageListing } from "./PackageListing";
import { filterPackages, useIsMounted, usePackages } from "@/util";

import LoadingIcon from "@/assets/images/icons/loading.svg";
import ReloadIcon from "@/assets/images/icons/reload.svg";
import { PackageSearchInput } from "./PackageSearchInput";

interface PackageSearchProps {}

const PackageSearch: React.FC<PackageSearchProps> = ({}) => {
  const isMounted = useIsMounted();
  const [packages, setPackages] = React.useState<Package[]>([]);
  const [filteredPackages, setFilteredPackages] = React.useState<Package[]>([]);
  const [searchQuery, setSearch] = React.useState<string>("");

  const setQuery = React.useCallback((value: string) => setSearch(value), []);

  const { data, isLoading, isError, isFetching, isIdle, refetch, isFetched } =
    usePackages();

  React.useEffect(() => {
    setPackages(data || []);
  }, [data, isFetched]);

  React.useEffect(() => {
    setFilteredPackages(filterPackages(packages, searchQuery));
  }, [packages, searchQuery]);

  const totalPackageCount = React.useMemo(
    () => packages?.length || 0,
    [packages]
  );

  const filteredPackageCount = React.useMemo(
    () => filteredPackages?.length || 0,
    [filteredPackages]
  );

  const pkgCountLabel = React.useMemo(
    () =>
      searchQuery === ""
        ? totalPackageCount
        : `${filteredPackageCount} / ${totalPackageCount}`,
    [filteredPackageCount, searchQuery, totalPackageCount]
  );

  const refetchPackages = () => {
    refetch({ stale: true });
  };

  return (
    <>
      <h2 className="flex flex-row space-x-2">
        <span>Packages</span>
        {isLoading ? (
          <LoadingIcon className="inline-block w-10 h-10 fill-current text-primary" />
        ) : (
          <>
            <small className="text-primary-400">({pkgCountLabel})</small>
            <button
              onClick={refetchPackages}
              title="Refetch Package List"
              disabled={isFetching}
            >
              <ReloadIcon
                title="Refetch"
                className={`w-5 h-5 fill-current text-primary ${
                  isFetching ? "animate-spin" : ""
                }`}
              />
            </button>
          </>
        )}
      </h2>
      <PackageSearchInput setQuery={setQuery} />
      {isLoading && (
        <div className="text-center">
          <LoadingIcon className="inline-block w-10 h-10 fill-current text-primary" />
        </div>
      )}
      {!isMounted && <SkeletonPackageListing />}
      {filteredPackages && isMounted && (
        <PackageList packages={packages} filteredPackages={filteredPackages} />
      )}
      {isIdle && !isError && filteredPackages.length === 0 && (
        <div className="text-center">No packages found.</div>
      )}
      {isError && (
        <div className="text-2xl text-center text-red-500">
          <p>Something went wrong...</p>
          <video
            className="w-1/3 mx-auto"
            autoPlay
            playsInline
            loop
            preload="auto"
          >
            <source
              src="https://giant.gfycat.com/GaseousHappyBigmouthbass.webm"
              type="video/webm"
            />
            <source
              src="https://giant.gfycat.com/GaseousHappyBigmouthbass.mp4"
              type="video/mp4"
            />
            <source
              src="https://thumbs.gfycat.com/GaseousHappyBigmouthbass-mobile.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      )}
    </>
  );
};

export { PackageSearch };
