export interface FormatDateProps {
  date: Date;
}

export const formatDate = ({ date }: FormatDateProps): string => {
  const locale =
    typeof window !== "undefined" ? window.navigator.language : "en-US";

  const prettyDate = new Intl.DateTimeFormat(locale, {
    dateStyle: "short",
    timeStyle: "short",
  }).format(date);
  return prettyDate;
};
